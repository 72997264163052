import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';

export default function Login() {
  const { loginUser } = useContext(AuthContext);
  const handleSubmit = e => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;
    username.length > 0 && loginUser(username, password);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label hmis-label">User ID</label>
          <input type="text" id="username" className="form-control hmis-field" />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label hmis-label">Password</label>
          <input type="password" id="password" className="form-control hmis-field" />
        </div>
        <div className="mb-3">
          <button type="submit" className="py-2.5 px-4 bg-blue-800 text-white text-lg font-semibold rounded-md shadow-lg shadow-blue-800 hover:bg-blue-600 focus:outline-none">Login</button>
        </div>
      </form>
    </React.Fragment>
  )
}
