import React, { useRef, useEffect, useState } from 'react'
// import './chart.css';
import _, { sortBy } from 'lodash';
import DataTable, { defaultThemes } from 'react-data-table-component';
import SideChartNav from './SideChartNav';
import { color } from 'd3';

export const BlockPerformance = ({ bestWorstPerformingBlock, toggleValue, indicatorCategory, indicatorVariant }) => {
    const componentRef = useRef();
    let mainTitle = `Block/Subdistrict Performance`;
    let bestBlockData = [], worstBlockData = [], tableData = [];

    let btColumns = [], wtColumns = [];

    const customTableStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'white',
                color: 'white',
                backgroundColor: '#1E3A8A',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
            },
        },
    };

    //BEST PERFORMING BLOCKS
    for (var i = 0; i < bestWorstPerformingBlock[0].bestPerforming.length; i++) {
        bestBlockData.push({
            [`Top 5 Blocks`]: { type: String, value: bestWorstPerformingBlock[0].bestPerforming[i].area.area_name },
            [`Percentage`]: !isNaN(bestWorstPerformingBlock[0].bestPerforming[i].per_data_value) ?
                { type: Number, value: (bestWorstPerformingBlock[0].bestPerforming[i].per_data_value) }
                : { type: String, value: '-' }

        })
    }


    for (const value in bestBlockData[0]) {
        btColumns.push({
            name: `${value.replace(/\w+/g, _.toUpper)}`,
            selector: row => row[value],
            sortable: true,
            center: true,
            // width: '50%',
        })
    };

    let btTableData = bestBlockData.map((x) => {
        let data = {};
        Object.keys(x).forEach((a) => {
            data = {
                ...data,
                [`${a}`]: x[`${a}`].value,
            }
        })
        return data;
    })

    //WORST PERFORMING BLOCKS
    for (var i = 0; i < bestWorstPerformingBlock[0].worstPerforming.length; i++) {
        worstBlockData.push({
            [`Bottom 5 Blocks`]: { type: String, value: bestWorstPerformingBlock[0].worstPerforming[i].area.area_name },
            [`Percentage`]: !isNaN(bestWorstPerformingBlock[0].worstPerforming[i].per_data_value) ?
                { type: Number, value: (bestWorstPerformingBlock[0].worstPerforming[i].per_data_value) }
                : { type: String, value: '-' }

        })
    }


    for (const value in worstBlockData[0]) {
        wtColumns.push({
            name: `${value.replace(/\w+/g, _.toUpper)}`,
            selector: row => row[value],
            sortable: true,
            center: true,
            width: '50%',
        })
    };

    let wtTableData = worstBlockData.map((x) => {
        let data = {};
        Object.keys(x).forEach((a) => {
            data = {
                ...data,
                [`${a}`]: x[`${a}`].value,
            }
        })
        return data;
    })

    //Table Data for EXCEL Download
    for (var i = 0; i < bestWorstPerformingBlock[0].bestPerforming.length; i++) {
        tableData.push({
            [`Top 5 Blocks`]: { type: String, value: bestWorstPerformingBlock[0].bestPerforming[i].area.area_name },
            [`Percentage`]: !isNaN(bestWorstPerformingBlock[0].bestPerforming[i].per_data_value) ?
                { type: Number, value: (bestWorstPerformingBlock[0].bestPerforming[i].per_data_value) }
                : { type: String, value: '-' },

            [`Bottom 5 Blocks`]: { type: String, value: bestWorstPerformingBlock[0].worstPerforming[i].area.area_name },
            [`Percentage`]: !isNaN(bestWorstPerformingBlock[0].worstPerforming[i].per_data_value) ?
                { type: Number, value: (bestWorstPerformingBlock[0].worstPerforming[i].per_data_value) }
                : { type: String, value: '-' }
        })
    }

    return (
        <>
            <div className="relative w-full h-full table-container">
                <div className="block absolute w-auto max-h-max right-0" style={{ zIndex: 2 }}>
                    <SideChartNav id="block-table" screen={null} title={mainTitle} table={tableData} componentRef={componentRef} />
                </div>
                <div className='relative w-full flex flex-col md:flex-row' id="block-table">
                    <div className="absolute right-2 left-2 md:right-5 md:left-5 mx-10 lg:mx-8 w-auto">
                        <div className="text-center font-bold md:font-semibold lg:font-bold text-sm md:text-base lg:text-base pb-8">{`${mainTitle}`}</div>
                    </div>
                    <br />
                    <br />
                    <div className='relative flex w-full md:w-1/2 pt-5'>
                        <DataTable
                            columns={btColumns}
                            data={btTableData}
                            responsive
                            highlightOnHover
                            customStyles={customTableStyles}
                            className='blockTable'
                        />
                    </div>

                    <div className='relative flex w-full md:w-1/2 pt-5'>
                        <DataTable
                            columns={wtColumns}
                            data={wtTableData}
                            responsive
                            highlightOnHover
                            customStyles={customTableStyles}
                            className='blockTable'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlockPerformance;