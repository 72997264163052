import React, { useRef, useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation';
import "./chart.css";
import { getColorArray, axisTitle, decimalPrecision } from '../util.js';
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Chart from 'chart.js/auto';
import fmt from 'indian-number-format';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

Chart.register(annotationPlugin);

export const BarChartHolder = ({ barChartData, indicatorType, toggleValue, indicatorCategory, indicatorVariant, minVal, maxVal }) => {

    const componentRef = useRef();
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
    }, []);

    let table = [];

    const indicatorName = barChartData[0].indicator.label;
    let showHideVar = true;
    let backgroundColors,
        graphTitle = `${indicatorName}, FY: ${barChartData[0].financial_year.fy_name}, Month: ${barChartData[0].month.month_name}`;

    let dataSort, dataValue,
        indicatorId = barChartData[0].indicator.indicator_id;

    // If only numeric values present then show numeric bar chart
    if (toggleValue === 'count') {
        showHideVar = false;
        dataSort = barChartData.sort(function (a, b) {
            return b.num_data_value - a.num_data_value;
        });

        dataValue = dataSort.map(x => x.num_data_value);

        if (barChartData) {
            for (let i = 0; i < barChartData.length; i++) {
                table.push({
                    Area: { type: String, value: barChartData[i].area.area_name },
                    //data: decimalPrecision(barChartData[i].per_data_value)
                    [`${barChartData[0].indicator.label}`]: !isNaN(barChartData[i].num_data_value) ?
                        { type: Number, value: (barChartData[i].num_data_value) }
                        : { type: String, value: '-' }
                })
            }
        };
    }
    else {
        dataSort = barChartData.sort(function (a, b) {
            return b.per_data_value - a.per_data_value;
        });

        dataValue = dataSort.map(x => x.per_data_value);

        if (barChartData) {
            for (var i = 0; i < barChartData.length; i++) {
                table.push({
                    Area: { type: String, value: barChartData[i].area.area_name },
                    //data: decimalPrecision(barChartData[i].per_data_value)
                    [`${barChartData[0].indicator.label}`]: !isNaN(barChartData[i].per_data_value) ?
                        { type: Number, value: decimalPrecision(barChartData[i].per_data_value) }
                        : { type: String, value: '-' }
                })

            }
        }
    }

    let rotateLabel = false;
    if (barChartData[0].area.area_parent_id === 22)
        rotateLabel = true;

    if (indicatorType === 'positive') {
        backgroundColors = getColorArray(dataValue, 'red', 'yellow', '#30A054', minVal, maxVal, toggleValue);
    }
    else {
        backgroundColors = getColorArray(dataValue, '#30A054', 'yellow', 'red', minVal, maxVal, toggleValue);
        showHideVar = false
    }

    var data = {
        labels: dataSort.map(x => x.area.area_name),
        //showTooltips: true,

        datasets: [{
            data: dataValue,
            backgroundColor: backgroundColors,
            borderWidth: 1,
        }]
    };

    var options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 9
                    }
                }
            },
            y: {
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: axisTitle(indicatorCategory, toggleValue, indicatorId, indicatorVariant),
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                }
            },
        },
        layout: {
            padding: {
                top: 32,
            }
        },
        plugins: {
            datalabels: {
                display: true,
                anchor: "end",
                align: "end",
                rotation: rotateLabel ? -90 : 0,
                formatter: function (value) {
                    return (toggleValue === "count") ? fmt.format(value) : Math.round(value);
                },
                labels: {
                    value: {
                        color: "black",
                        font: {
                            size: 11
                        }
                    }
                }
            },
            annotation: {
                annotations: [{
                    type: 'line',
                    mode: 'horizontal',
                    value: 100,
                    scaleID: "y",
                    borderWidth: 4,
                    borderDash: [10, 5],
                    borderColor: showHideVar ? "black" : "transparent",
                }]
            },
            legend: {
                display: false

            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let val = context.raw;
                        return (toggleValue === "count") ? fmt.format(val) : decimalPrecision(val);
                    }
                },
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },

            },
            hover: {
                mode: 'nearest',
                intersect: true
            }


        }

    };


    const screen = useFullScreenHandle();
    let height, paddingValue;

    if (screen.active === true) {
        height = '70vh';
        paddingValue = '3rem';

    } else
        height = '40vh';

    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementsByClassName('barChartDiv')[0].parentElement.clientWidth > 780) {
                document.getElementById('barChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                canvasRef.current.options.scales.x.ticks.font.size = 24;
                canvasRef.current.options.scales.y.ticks.font.size = 24;
                canvasRef.current.options.scales.y.title.font.size = 24;
                document.getElementById('barChart').setAttribute('style', 'padding-top:20px')

                options.plugins.tooltip.bodyFont.size = 24;
                options.plugins.tooltip.titleFont.size = 24;
                options.plugins.datalabels.labels.value.font.size = 22;
            }
        }
        else {
            document.getElementById('barChartTitle').setAttribute('style', 'font-size: 1rem;');
            canvasRef.current.options.scales.x.ticks.font.size = 9;
            canvasRef.current.options.scales.y.ticks.font.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            document.getElementById('barChart').setAttribute('style', 'padding-top:0px')

            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            options.plugins.datalabels.labels.value.font.size = 11;
        }
    };
    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange} >
                <div className="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max right-0" style={{ zIndex: 2 }}>
                        <SideChartNav id="barChart" screen={screen} title={graphTitle} table={table} componentRef={componentRef} />
                    </div>
                    <div className='barChartDiv relative w-full h-full' id="barChart" ref={componentRef}>
                        <div className="absolute right-2 left-2 md:right-5 md:left-5 mx-10 lg:mx-8 w-auto">
                            <div id='barChartTitle' className="graph-title text-center font-bold md:font-semibold text-sm lg:font-bold">{`${graphTitle}`} </div>
                        </div>
                        <div className='barDiv relative w-full pt-14 h-full items-center'>
                            <Bar data={data} options={options} className="items-center" style={{ position: 'relative', height: height, width: '80vw', padding: paddingValue }} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default BarChartHolder