import React, { Component, useState, useEffect, useRef } from 'react'
import { fetchDataCompare, getIndicatorArray } from '../util.js'
import useAxios from '../pages/useAxios';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';


const ValueContainer = ({ children, getValue, ...props }) => {
    var values = getValue();
    var valueLabel = "";

    if (values.length > 0)
        valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1) valueLabel += ` & ${values.length - 1} more`;

    // Keep standard placeholder and input from react-select
    var childsToRender = React.Children.toArray(children).filter(
        child =>
            ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );

    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue && valueLabel}
            {childsToRender}
        </components.ValueContainer>
    );
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export const IndicatorCompare = ({ fyear, district, sub_district, month, area, indicator, areaType, indicatorCategory, districtDropdown, subdtDropdown, fyDropdown, monthDropdown, indicatorDropdown, setAreaType, setIndicator, setArea, setMonth, setFyear, setDistrict, setSubDt, status, setbarChartData, setLineChartData, setTableData, setQualityData, isSelected, setIsSelected, toggleValue, setFound }) => {
    const api = useAxios();
    const [selectedIndicators, setSelectedIndicators] = useState();

    const updatedIndicators = async (e) => {
        setSelectedIndicators(e);
    };

    const handleFinancialYear = async (e) => {
        setIsSelected(false);

        const fyearSelected = e.target.value;
        setFyear(fyearSelected);
        await fetchDataCompare(fyearSelected, month, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleMonthChange = async (e) => {
        setIsSelected(false);

        let monthSelected = e.target.value;
        setMonth(monthSelected);
        await fetchDataCompare(fyear, monthSelected, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);

    };

    const handleIndicatorChange = async (e) => {
        setIsSelected(false);
        let val = Array.isArray(selectedIndicators) ? selectedIndicators.map(x => x.value) : [selectedIndicators.value];
        setIndicator(val);
        let selectedIndiVal = val;
        let indiArray = getIndicatorArray(indicatorDropdown, selectedIndiVal, toggleValue);

        if (indiArray.length === 0)
            setFound(false)
        else {
            setFound(true);
            let indicatorSelected = indiArray.join(',');
            await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        }
        setIsSelected(true);
    };

    const handleDistrictChange = async (e) => {
        setIsSelected(false);

        const districtName = e.target.value;
        let areaSelected = 22
        let areaTypeSelected = 'District';
        if (districtName == 22) {
            setAreaType('District');
            setArea(22);
            setSubDt(3652);
        }
        else {
            setAreaType('SubDistrict');
            areaSelected = districtName;
            areaTypeSelected = 'SubDistrict';
            setArea(districtName);
        }
        setDistrict(districtName);

        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        setIsSelected(true);
    };

    const handleSubDistrictChange = async (e) => {
        setIsSelected(false);

        const subdtName = e.target.value;
        let areaSelected = district;
        let areaTypeSelected = 'SubDistrict';
        setAreaType('SubDistrict');
        setArea(subdtName);

        if (subdtName == 3652) {
            setAreaType('SubDistrict');
            setArea(district);
        }
        else {
            setAreaType('SubDistrict');
            setArea(subdtName);
            areaTypeSelected = 'SubDistrict';
            areaSelected = subdtName;
        }

        setSubDt(subdtName);
        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        setIsSelected(true);
    };


    return (
        <React.Fragment>
            <form action="" className='mb-3'>
                <div className="flex flex-wrap md:flex-row">
                    <div className='flex w-full md:w-1/3'>
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Indicator</label>
                            <Select
                                className="bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                options={indicatorDropdown.sort((a, b) => a.category - b.category).map(e => ({ label: e.label, value: e.indicator_id }))}
                                closeMenuOnSelect={false}// set list of the data
                                onChange={updatedIndicators} // assign onChange function
                                // isOptionDisabled={() => indicator.length >= 7}
                                isMulti
                                defaultValue={{ label: "PW given Td Booster", value: 23 }}
                                // maximumSelectionLength={3}
                                maxMenuHeight="50"
                                showCheckbox={true}
                                hideSelectedOptions={false}
                                onMenuClose={handleIndicatorChange}
                                isDisabled={!isSelected}
                                components={{
                                    ValueContainer,
                                    Option
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full md:w-2/3 flex-wrap'>
                        <div className="flex w-full md:w-1/2 lg:w-1/4">
                            <div className='flex-col w-11/12'>
                                <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Financial Year</label>
                                <select name="year" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                    value={fyear}
                                    onChange={handleFinancialYear}
                                    disabled={!isSelected} >
                                    <option value='All'>All</option>

                                    {fyDropdown.map((year) => {
                                        return (
                                            <option value={year.fy_id}>{year.fy_name}</option>
                                        )
                                    })
                                    }

                                </select>
                            </div>
                        </div>
                        <div className="flex w-full md:w-1/2 lg:w-1/4">
                            <div className='flex-col w-11/12'>
                                <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Month</label>
                                <select name="month" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                    value={month}
                                    onChange={handleMonthChange}
                                    disabled={!isSelected}>
                                    {monthDropdown.map((month) => {
                                        return (
                                            <option value={month.month_id}>{month.month_name}</option>
                                        )
                                    })
                                    }

                                </select>
                            </div>
                        </div>
                        <div className="flex w-full md:w-1/2 lg:w-1/4">
                            <div className='flex-col w-11/12'>
                                <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select District</label>
                                <select name="district" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                    value={district}
                                    onChange={handleDistrictChange}
                                    disabled={!isSelected}>
                                    {/* <option value={22}>All (MH)</option> */}
                                    {
                                        districtDropdown.map((data) => {
                                            return (
                                                <option value={data.area_id}>{data.area_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="flex w-full md:w-1/2 lg:w-1/4">
                            <div className='flex-col w-11/12'>
                                <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Block/Taluka</label>
                                <select name="subdt" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                    value={sub_district}
                                    onChange={handleSubDistrictChange}
                                    disabled={!isSelected}>
                                    <option value={3652}>All</option>
                                    {
                                        subdtDropdown.filter(sub_district => sub_district.area_parent_id == district).map(filtered_subdt => {
                                            return (

                                                <option value={filtered_subdt.area_id}>{filtered_subdt.area_name}</option>
                                            )

                                        }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </React.Fragment>
    )
}

export default IndicatorCompare