import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import Popup from "./Popup/Popup";
import { MdDownload, MdFullscreen, MdCloseFullscreen, MdTableChart, MdMenu, MdPrint } from "react-icons/md";
import jsPDF from 'jspdf';
import { useReactToPrint } from "react-to-print";
import "./SideChartNav.css";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import DataTable, { defaultThemes } from 'react-data-table-component';
import writeXlsxFile from 'write-excel-file'
import _ from 'lodash';

const SideChartNav = ({ id, screen, title, table, componentRef }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isOpenTable, setIsOpenTable] = useState(false);
    const [icon, setIcon] = useState(<MdFullscreen className="md-icon" />);
    const [text, setText] = useState('Full View')

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const toggleTablePopup = () => {
        setIsOpenTable(!isOpenTable);
    }

    //toggle fullscreen
    const OpenFullscreen = () => {
        setIcon(<MdCloseFullscreen className="md-icon" />);
        setText("Exit");
        setIsFullscreen(true);
        let mapTooltips = document.getElementsByClassName("tooltip")
        setTimeout(() => {
            for (let j = 0; j < mapTooltips.length; j++) {
                mapTooltips[j].style.opacity = 0;
            }
        }, 1000);
    }

    const closeFullscreen = () => {
        setIcon(<MdFullscreen className="md-icon" />);
        setText("Full View");
        setIsFullscreen(false);
    }

    //Handle ESC button fullscreen exit
    if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
    }

    function exitHandler() {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            closeFullscreen();
        }
    }

    //print
    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page {
                size: 1100mm 750mm;
                margin: 0;
            }
          }`,
        content: () => componentRef.current
    });

    // set white background of downloaded image

    const saveJpeg = () => {
        if (id.includes("Map")) {
            htmlToImage.toJpeg(document.getElementById(id), { canvasWidth: 7000, canvasHeight: 5000, backgroundColor: 'white' })
                .then(function (dataUrl) {
                    download(dataUrl, id);
                })
        }
        else {
            htmlToImage.toJpeg(document.getElementById(id), { backgroundColor: 'white' })
                .then(function (dataUrl) {
                    download(dataUrl, id);
                })
        }
    }

    const savePng = () => {
        if (id.includes("Map")) {
            htmlToImage.toPng(document.getElementById(id), { quality: 1 }).then(function (dataUrl) {
                download(dataUrl, id);
            })
        } else {
            htmlToImage.toPng(document.getElementById(id), { quality: 1, backgroundColor: 'white' }).then(function (dataUrl) {
                download(dataUrl, id);
            })
        }
    }

    const saveSvg = () => {
        htmlToImage.toSvg(document.getElementById(id), { backgroundColor: 'white' })
            .then(function (dataUrl) {
                let link = document.createElement('a');
                link.download = id;
                link.href = dataUrl;
                link.click();
            });
    }

    const savePdf = () => {
        if (['block-table', 'dataQuality'].includes(id)) {
            htmlToImage
                .toPng(document.getElementById(id), { quality: 1, width: 2500 })
                .then(function (dataUrl) {
                    var link = document.createElement('a');
                    link.download = id;
                    const pdf = new jsPDF('p', 'mm', 'a0');

                    let img = new Image();
                    img.src = dataUrl;
                    img.onload = function () {
                        var imgHeight = img.height;
                        var imgWidth = img.width;

                        let width = pdf.internal.pageSize.getWidth();
                        let hratio = imgHeight / imgWidth;
                        let imgPDFHeight = width * hratio;

                        pdf.addImage(dataUrl, 'PNG', 5, 5, width, imgPDFHeight);
                        pdf.save(id);
                    };
                })
                .catch((error) => console.error('oops, something went wrong!', error));
        } else {
            if (id.includes("Map")) {
                htmlToImage
                    .toPng(document.getElementById(id), { quality: 1 })
                    .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = id;
                        const pdf = new jsPDF();

                        let img = new Image();
                        img.src = dataUrl;
                        img.onload = function () {
                            var imgHeight = img.height;
                            var imgWidth = img.width;

                            let width = pdf.internal.pageSize.getWidth();
                            let hratio = imgHeight / imgWidth;
                            let imgPDFHeight = width * hratio;

                            pdf.addImage(dataUrl, 'PNG', 0, 0, width, imgPDFHeight);
                            pdf.save(id);
                        };
                    })
                    .catch((error) => console.error('oops, something went wrong!', error));
            }
            else {
                htmlToImage
                    .toPng(document.getElementById(id), { quality: 1, canvasWidth: 1100, canvasHeight: 750 })
                    .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = id;
                        const pdf = new jsPDF();

                        let img = new Image();
                        img.src = dataUrl;
                        img.onload = function () {
                            var imgHeight = img.height;
                            var imgWidth = img.width;

                            let width = pdf.internal.pageSize.getWidth();
                            let hratio = imgHeight / imgWidth;
                            let imgPDFHeight = width * hratio;

                            pdf.addImage(dataUrl, 'PNG', 0, 0, width, imgPDFHeight);
                            pdf.save(id);
                        };
                    })
                    .catch((error) => console.error('oops, something went wrong!', error));
            }
        }
    };

    const saveXlsx = async () => {
        let INDICATOR_TITLE_ROW = [
            {
                value: `${title}`,
                fontWeight: 'bold',
                height: 50,
                span: 8,
                alignVertical: 'center',
                align: 'left',
                fontSize: 16,
                wrap: true,
                borderStyle: 'thin',
            }
        ]

        let DATA_ROW_HEADER = [];

        for (let i = 0; i < Object.keys(table[0]).length; i++) {
            DATA_ROW_HEADER.push({
                value: `${Object.keys(table[0])[i]}`,
                span: 4,
                height: 30,
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                wrap: true,
            }, null, null, null)
        }

        let data = [
            INDICATOR_TITLE_ROW,
            DATA_ROW_HEADER,
        ];

        INDICATOR_TITLE_ROW[0].span = DATA_ROW_HEADER.length;

        // table.pop();
        table.forEach((x, index) => {
            let row = [];
            Object.keys(x).forEach((key) => {
                row.push({
                    type: x[`${key}`].type,
                    value: x[`${key}`].type === Number ? parseFloat(x[`${key}`].value) : `${x[`${key}`].value}`,
                    span: 4,
                    height: 20,
                    alignVertical: 'center',
                    align: 'center',
                    format: 0.00,
                }, null, null, null)
            });
            data.push(row);
        });

        await writeXlsxFile(data, {
            columns, // (optional) column widths, etc.
            fileName: `${id}.xlsx`
        });
    }

    const customStyles = {
        headCells: {
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
                backgroundColor: '#A2D3FA',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
            },
        },
    };


    let columns = [];

    for (const value in table[0]) {
        columns.push({
            name: `${value.replace(/\w+/g, _.toUpper)}`,
            selector: row => row[value],
            sortable: true,
            center: true,
        })
    };

    let tableData = table.map((x) => {
        let data = {};
        Object.keys(x).forEach((a) => {
            data = {
                ...data,
                [`${a}`]: x[`${a}`].value,
            }
        })
        return data;
    })

    return (
        <>
            {isOpenTable && <Popup
                content={<>
                    <DataTable
                        title={title}
                        columns={columns}
                        data={tableData}
                        responsive
                        highlightOnHover
                        customStyles={customStyles}
                    />
                </>}
                handleClose={toggleTablePopup}
            />}

            {isOpen && <Popup
                content={<div className="popup-container">
                    <button onClick={saveXlsx} id="btn">XLSX</button>
                    {(id !== "color-coded-table") ? <button onClick={saveJpeg} id="btn">JPEG</button> : null}
                    {(id !== "color-coded-table") ? <button onClick={savePng} id="btn">PNG</button> : null}
                    {(id !== "color-coded-table") ? <button onClick={savePdf} id="btn">PDF</button> : null}
                    <button onClick={saveSvg} id="btn">SVG</button>
                </div>}
                handleClose={togglePopup}
            />}

            <Dropdown style={{ float: 'right' }} className="">
                <Dropdown.Toggle variant="link" bsPrefix="p-0 mt-0">
                    <MdMenu id="icon" className="" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="" style={{ margin: 0 }}>
                    {id && <Dropdown.Item onClick={togglePopup} eventKey="1" style={{ fontSize: '15px' }}><MdDownload className="md-icon" /> Download</Dropdown.Item>}
                    {screen && <Dropdown.Item
                        onClick={(e) => {
                            if (!isFullscreen) {
                                screen.enter();
                                OpenFullscreen();
                            } else {
                                screen.exit();
                                closeFullscreen();
                            }
                        }}
                        eventKey="2" style={{ fontSize: '15px' }}>{icon} {text}
                    </Dropdown.Item>}
                    {(!['block-table', 'dataQuality'].includes(id)) && table.length > 0 && <Dropdown.Item onClick={toggleTablePopup} eventKey="3" style={{ fontSize: '15px' }}><MdTableChart className="md-icon" style={{ fontSize: '20px' }} /> Table</Dropdown.Item>}
                    {(id !== "color-coded-table") && componentRef && <Dropdown.Item onClick={handlePrint} eventKey="5" style={{ fontSize: '15px' }}><MdPrint className="md-icon" style={{ fontSize: '20px' }} /> Print</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}
export default SideChartNav;