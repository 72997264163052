import React, { Component, useState, useEffect, useRef } from 'react'
import { fetchDataCompare } from '../util.js'
import useAxios from '../pages/useAxios';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';


const ValueContainer = ({ children, getValue, ...props }) => {
    var values = getValue();
    var valueLabel = "";

    if (values.length > 0)
        valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1) valueLabel += ` & ${values.length - 1} more`;

    // Keep standard placeholder and input from react-select
    var childsToRender = React.Children.toArray(children).filter(
        child =>
            ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );

    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue && valueLabel}
            {childsToRender}
        </components.ValueContainer>
    );
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export const FYCompare = ({ fyear, district, sub_district, month, area, indicator, areaType, indicatorCategory, districtDropdown, subdtDropdown, fyDropdown, indicatorDropdown, setAreaType, setIndicator, setArea, setMonth, setFyear, setDistrict, setSubDt, status, setbarChartData, setLineChartData, setTableData, setQualityData, isSelected, setIsSelected, toggleValue, setFound }) => {
    const api = useAxios();
    const [selectedFYs, setSelectedFYs] = useState();
    const updatedFYs = async (e) => {
        setSelectedFYs(e);
    };

    let found_1 = indicatorDropdown.filter(e => e.indicator_id === indicator[0])[0].variant;

    if (toggleValue === 'count') {
        if (found_1 === 'Numeric' || found_1 === 'Both') {
            setFound(true);
        } else {
            setFound(false);
        }
    } else {
        if (found_1 === 'Percent' || found_1 === 'Both') {
            setFound(true);
        } else {
            setFound(false);
        }
    }

    const handleFinancialYear = async (e) => {
        setIsSelected(false);

        let val = Array.isArray(selectedFYs) ? selectedFYs.map(x => x.value) : [selectedFYs.value];
        setFyear(val);

        let fyearSelected = val.join(',');
        await fetchDataCompare(fyearSelected, month, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleIndicatorChange = async (e) => {
        setIsSelected(false);

        let val = e.target.value;
        setIndicator([Number(val)]);

        let indicatorSelected = val;
        await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleDistrictChange = async (e) => {
        setIsSelected(false);

        const districtName = e.target.value;
        let areaSelected = 22
        let areaTypeSelected = 'District';
        if (districtName == 22) {
            setAreaType('District');
            setArea(22);
            setSubDt(3652);
        }
        else {
            setAreaType('SubDistrict');
            areaSelected = districtName;
            areaTypeSelected = 'SubDistrict';
            setArea(districtName);
        }
        setDistrict(districtName);

        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        setIsSelected(true);
    };

    const handleSubDistrictChange = async (e) => {
        setIsSelected(false);

        const subdtName = e.target.value;
        let areaSelected = district;
        let areaTypeSelected = 'SubDistrict';
        setAreaType('SubDistrict');
        setArea(subdtName);

        if (subdtName == 3652) {
            setAreaType('SubDistrict');
            setArea(district);
        }
        else {
            setAreaType('SubDistrict');
            setArea(subdtName);
            areaTypeSelected = 'SubDistrict';
            areaSelected = subdtName;
        }

        setSubDt(subdtName);
        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        setIsSelected(true);
    };


    return (
        <React.Fragment>
            <form action="" className="mb-3">
                <div className="flex flex-wrap md:flex-row">
                    <div className='flex w-full lg:w-1/5'>
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Financial Year</label>
                            <Select
                                className='bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full'
                                options={fyDropdown.map(e => ({ label: e.fy_name, value: e.fy_id }))}
                                closeMenuOnSelect={false}// set list of the data
                                onChange={updatedFYs} // assign onChange function
                                // isOptionDisabled={() => fyear.length >= 7}
                                isMulti
                                defaultValue={{ label: "2019-2020", value: 3 }}
                                maxMenuHeight="50"
                                showCheckbox={true}
                                hideSelectedOptions={false}
                                onMenuClose={handleFinancialYear}
                                isDisabled={!isSelected}
                                components={{
                                    ValueContainer,
                                    Option
                                }}
                            />
                        </div>
                    </div>

                    <div className='flex w-full lg:w-2/5'>
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Indicator</label>
                            <select name="indicator" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                value={indicator}
                                onChange={handleIndicatorChange}
                                disabled={!isSelected}>
                                {indicatorDropdown.sort((a, b) => a.category - b.category).map((indicator) => {
                                    return (
                                        <option value={indicator.indicator_id}>{indicator.label}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>

                    <div className='flex w-full md:w-1/2 lg:w-1/5'>
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select District</label>
                            <select name="district" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                value={district}
                                onChange={handleDistrictChange}
                                disabled={!isSelected}>
                                {
                                    districtDropdown.map((data) => {
                                        return (
                                            <option value={data.area_id}>{data.area_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className='flex w-full md:w-1/2 lg:w-1/5'>
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Block/Taluka</label>
                            <select name="subdt" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                value={sub_district}
                                onChange={handleSubDistrictChange}
                                disabled={!isSelected}>
                                <option value={3652}>All</option>
                                {
                                    subdtDropdown.filter(sub_district => sub_district.area_parent_id == district).map(filtered_subdt => {
                                        return (

                                            <option value={filtered_subdt.area_id}>{filtered_subdt.area_name}</option>
                                        )
                                    }
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

export default FYCompare