import React, { Component, useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer'
import IndicatorCompare from '../dashboard_components/IndicatorCompare'
import AreaCompare from '../dashboard_components/AreaCompare'
import FYCompare from '../dashboard_components/FYCompare'
import IndicatorCompareLineChart from '../dashboard_components/IndicatorCompareLineChart'
import IndicatorCompareBarChart from '../dashboard_components/IndicatorCompareBarChart'
import AreaCompareLineChart from '../dashboard_components/AreaCompareLineChart'
import FYCompareLineChart from '../dashboard_components/FYCompareLineChart'
import { fetchDataCompare, getIndicatorArray } from '../util.js'
import useAxios from './useAxios';
import { SkeletonIndiComp, SkeletonCompare } from "./SkeletonCard";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthContext from '../context/AuthContext';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';


export default function Compare() {

    const [level, setLevel] = useState("district");
    const [barChartData, setbarChartData] = useState([]);
    const [indicator, setIndicator] = useState([23]);
    const [fyear, setFyear] = useState([3]);
    const [month, setMonth] = useState(13);
    const [district, setDistrict] = useState([22]);
    const [sub_district, setSubDt] = useState([3652]);
    const [area, setArea] = useState([22]);
    const [areaType, setAreaType] = useState('District');
    const [indicatorType, setIndicatorType] = useState('negative');
    const [indicatorCategory, setIndicatorCategory] = useState(1);
    const [areaDetails, setAreaDetails] = useState([]);
    const [districtDropdown, setDistrictDropdown] = useState([]);
    const [subdtDropdown, setSubDtDropdown] = useState([]);
    const [fyDropdown, setFYDropdown] = useState([]);
    const [monthDropdown, setMonthDropdown] = useState([]);
    const [indicatorDropdown, setIndicatorDropdown] = useState([]);
    const location = useLocation();
    const [lineChartData, setLineChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [qualityData, setQualityData] = useState([]);
    const [toggleState, setToggleState] = useState(true);

    const [results, setResults] = useState([]);
    const [status, setStatus] = useState("indicator")
    const [isSelected, setIsSelected] = useState(true);
    const [toggleValue, setToggleValue] = useState('percent');
    const [found, setFound] = useState(true);
    let { authtokens, user, logoutUser } = useContext(AuthContext);
    let dtAccessID = user.areaIDs;

    const api = useAxios();
    let SkeletonScreen, pdfPath;

    const onChangeValue = async (e) => {
        setIsSelected(false);
        const statusVal = e.target.value;

        setFyear([3]);
        setMonth([13]);
        setArea([22]);
        setIndicator([23]);
        setDistrict([22]);
        setSubDt([3652]);
        setAreaType('District');
        let fyReset = [3];
        let areaReset = [22];
        let indicatorReset = [23];
        let monthReset = 13;
        let areaTypeReset = 'District';
        let indicator_cat = 1;
        setStatus(statusVal);

        await fetchDataCompare(fyReset, monthReset, areaReset, indicatorReset, areaTypeReset, indicator_cat, statusVal, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    }

    useEffect(() => {
        setIsSelected(false);
        async function getInitialData() {
            const [response1, response2, response3, response4] = await Promise.all([
                api.get(`/area/${dtAccessID}`),
                api.get('/fy'),
                api.get('/month'),
                api.get('/indicators')
            ]);

            let areaData = response1.data.filter(f => (f.area_level != 5));
            setAreaDetails(areaData);
            let districtData = areaData.filter(f => (f.area_type === 'District'));
            let subDtData = areaData.filter(f => (f.area_type === 'SubDistrict') & (f.area_id != 3652));
            setDistrictDropdown(districtData);
            setSubDtDropdown(subDtData);

            let fyData, indicatorData;
            if (['5', '6'].includes(user.id)) {
                fyData = response2.data.filter(m => (m.fy_id < 5));
                indicatorData = response4.data.filter(i => i.indicator_id < 46);
            }
            else {
                fyData = response2.data;
                indicatorData = response4.data;
            }

            setFYDropdown(fyData);
            setIndicatorDropdown(indicatorData);

            let monthData = response3.data.filter(m => (m.month_id < 14));
            setMonthDropdown(monthData);

            await fetchDataCompare(fyear, month, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
            setIsSelected(true);
        }
        getInitialData();

    }, []);

    const ToggleButtonChange = async (event, newAlignment) => {
        setIsSelected(false);
        setToggleValue(newAlignment);
        let indicator_values = indicator;
        if (newAlignment === 'count') {
            let indiArray = getIndicatorArray(indicatorDropdown, indicator_values, newAlignment);
            if (indiArray.length === 0) {
                setFound(false);
            }
            else {
                let indicatorSelected = indiArray.join(',');
                setFound(true);
                await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
            }

        } else {
            let indiArray = getIndicatorArray(indicatorDropdown, indicator_values, newAlignment);
            if (indiArray.length === 0) {
                setFound(false);
            }
            else {
                let indicatorSelected = indiArray.join(',');
                setFound(true);
                await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
            }

        }
        setIsSelected(true);
    };

    const theme = createTheme({
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        border: `1px solid #000`,
                        color: '#000',
                        fontWeight: 'bold',
                    },
                },
            },
        },
    });

    let ToggleButtonSwitch = <ThemeProvider theme={theme}>
        <ToggleButtonGroup
            color="info"
            value={toggleValue}
            fullWidth={true}
            exclusive
            onChange={ToggleButtonChange}
            aria-label="Platform"
            variant="contained"
        >
            <ToggleButton value="percent">Percent</ToggleButton>
            <ToggleButton value="count">Count</ToggleButton>
        </ToggleButtonGroup>
    </ThemeProvider>


    if (status === 'fy' || status === 'area') {
        SkeletonScreen = <SkeletonCompare />;
    } else {
        SkeletonScreen = <SkeletonIndiComp />;
    }

    const downloadAll = async () => {
        let lineChartURL, barChartURL, imageURLs;
        if (status === "indicator") {
            if (fyear != 'All' && area < 441) {
                lineChartURL = await htmlToImage.toPng(document.getElementById('indicatorLineChart'), { quality: 1 });
                barChartURL = await htmlToImage.toPng(document.getElementById('indicatorBarChart'), { quality: 1 });
                imageURLs = [lineChartURL, barChartURL]
            }
            else {
                lineChartURL = await htmlToImage.toPng(document.getElementById('indicatorLineChart'), { quality: 1 });
                imageURLs = [lineChartURL]
            }
        } else if (status === "fy") {
            lineChartURL = await htmlToImage.toPng(document.getElementById('fyLineChart'), { quality: 1 });
            imageURLs = [lineChartURL];
        } else {
            lineChartURL = await htmlToImage.toPng(document.getElementById('areaLineChart'), { quality: 1 });
            imageURLs = [lineChartURL];
        }


        const pdf = new jsPDF("l");
        let width = pdf.internal.pageSize.getWidth();
        let height = pdf.internal.pageSize.getHeight();


        pdf.setFontSize(30);
        pdf.setFont(undefined, "bold");
        pdf.text(`Maharashtra HMIS - Data Analysis and Visualization`, (width / 2) / 7, height / 8);
        pdf.setFontSize(15)
        pdf.text(`(Compare Page)`, width / 2, height / 6, { align: 'center' });

        pdf.addImage('/assets/img/pregnancy.png', 'PNG', 30, height / 3, 50, 50);
        pdf.addImage('/assets/img/deliveries.png', 'PNG', 95, height / 3, 50, 50);
        pdf.addImage('/assets/img/child_immunisation.png', 'PNG', 160, height / 3, 50, 50);
        pdf.addImage('/assets/img/child_disease.png', 'PNG', 225, height / 3, 50, 50);

        pdf.setFontSize(10);
        pdf.text(`Developed in Collaboration with`, width / 1.8, height - 20);
        pdf.addImage('/assets/img/nhm_mh_logo.png', 'PNG', 230, height - 25, 10, 10);
        pdf.addImage('/assets/img/iitb.png', 'PNG', 245, height - 25, 10, 10);
        pdf.addImage('/assets/img/ctara.png', 'PNG', 260, height - 23, 20, 7);
        pdf.addPage();


        imageURLs.forEach((url, index) => {
            let link = document.createElement('a');
            link.download = 'imageNameJpeg';

            let img = new Image();
            img.src = url;
            img.onload = function () {

                pdf.addImage(url, 'PNG', 0, 0, width - 10, height - 10);
                pdf.addPage();
                if (index === (imageURLs.length - 1)) {
                    pdf.deletePage(pdf.internal.getNumberOfPages());
                    pdf.save('ComparePage.pdf');
                }

            }
        });
    };

    return (
        <React.Fragment>
            <Header />

            <div className="container-fluid">
                <div className="row">
                    {/* <SideMenu /> */}
                    <main className="col hmis-dashboard-main md:mx-2.5">
                        <div className="container-2xl m-0">
                            <div className="flex md:flex-wrap flex-wrap md:mb-3 md:px-1">
                                <div className="flex w-full md:w-3/4">
                                    <h2 className="text-3xl font-bold text-black md:text-2xl lg:text-3xl">MH-HMIS Dashboard <span className="hmis-beta">Beta</span></h2>
                                </div>
                                <div className="flex flex-row w-full md:w-1/4 justify-center">
                                    <button type="button" className="download-button my-1 px-4 py-2" onClick={downloadAll}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#1E3A8A" className="inline pb-1" height="20" width="20" viewBox="0 0 512 512">
                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                        </svg> Download All
                                    </button>
                                    {/* <Link to="#">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-md mb-1 py-2 px-4 mt-1 rounded-full">Cumulative</button>
                                    </Link> */}
                                    <Link to="/dashboard">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-md mb-1 ml-3 py-2 px-4 mt-1 rounded-full">Back</button>
                                    </Link>
                                </div>
                            </div>

                            <div className="flex flex-col flex-wrap md:flex-row mx-3" onChange={onChangeValue}>
                                <div className="flex w-full md:w-1/2 lg:w-1/3">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="indicator" checked={status === "indicator"} />
                                    <label className="ml-2 text-base font-medium text-black" htmlFor="inlineRadio1">Indicator wise Comparison</label>
                                </div>
                                <div className="flex w-full md:w-1/2 lg:w-1/3">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="fy" checked={status === "fy"} />
                                    <label className="ml-2 text-base font-medium text-black" htmlFor="inlineRadio2">Financial Year wise Comparison</label>
                                </div>
                                <div className="flex w-full md:w-1/2 lg:w-1/3">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="area" checked={status === "area"} />
                                    <label className="ml-2 text-base font-medium text-black" htmlFor="inlineRadio3">Area wise Comparison</label>
                                </div>
                            </div>

                            {status === "indicator" ?
                                <IndicatorCompare fyear={fyear}
                                    district={district}
                                    sub_district={sub_district}
                                    month={month}
                                    area={area}
                                    indicator={indicator}
                                    areaType={areaType}
                                    indicatorCategory={indicatorCategory}
                                    districtDropdown={districtDropdown}
                                    subdtDropdown={subdtDropdown}
                                    fyDropdown={fyDropdown}
                                    monthDropdown={monthDropdown}
                                    indicatorDropdown={indicatorDropdown}
                                    setAreaType={setAreaType}
                                    setIndicator={setIndicator}
                                    setArea={setArea}
                                    setMonth={setMonth}
                                    setFyear={setFyear}
                                    setDistrict={setDistrict}
                                    setSubDt={setSubDt}
                                    status={status}
                                    setbarChartData={setbarChartData}
                                    setLineChartData={setLineChartData}
                                    setTableData={setTableData}
                                    setQualityData={setQualityData}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    toggleValue={toggleValue}
                                    setFound={setFound}
                                /> : null}

                            {status === "area" ?
                                <AreaCompare fyear={fyear}
                                    district={district}
                                    sub_district={sub_district}
                                    month={month}
                                    area={area}
                                    indicator={indicator}
                                    areaType={areaType}
                                    indicatorCategory={indicatorCategory}
                                    districtDropdown={districtDropdown}
                                    subdtDropdown={subdtDropdown}
                                    fyDropdown={fyDropdown}
                                    indicatorDropdown={indicatorDropdown}
                                    setAreaType={setAreaType}
                                    setIndicator={setIndicator}
                                    setArea={setArea}
                                    setMonth={setMonth}
                                    setFyear={setFyear}
                                    setDistrict={setDistrict}
                                    setSubDt={setSubDt}
                                    level={level}
                                    setLevel={setLevel}
                                    status={status}
                                    setbarChartData={setbarChartData}
                                    setLineChartData={setLineChartData}
                                    setTableData={setTableData}
                                    setQualityData={setQualityData}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    toggleValue={toggleValue}
                                    setFound={setFound}
                                /> : null}

                            {status === "fy" ?
                                <FYCompare fyear={fyear}
                                    district={district}
                                    sub_district={sub_district}
                                    month={month}
                                    area={area}
                                    indicator={indicator}
                                    areaType={areaType}
                                    indicatorCategory={indicatorCategory}
                                    districtDropdown={districtDropdown}
                                    subdtDropdown={subdtDropdown}
                                    fyDropdown={fyDropdown}
                                    indicatorDropdown={indicatorDropdown}
                                    setAreaType={setAreaType}
                                    setIndicator={setIndicator}
                                    setArea={setArea}
                                    setMonth={setMonth}
                                    setFyear={setFyear}
                                    setDistrict={setDistrict}
                                    setSubDt={setSubDt}
                                    status={status}
                                    setbarChartData={setbarChartData}
                                    setLineChartData={setLineChartData}
                                    setTableData={setTableData}
                                    setQualityData={setQualityData}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    toggleValue={toggleValue}
                                    setFound={setFound}
                                /> : null}

                            <div className="flex my-2 md:my-0 md:px-1 items-center">
                                <div className='flex w-full lg:w-1/5 justify-center'>
                                    <div className='w-11/12'>
                                        {ToggleButtonSwitch}
                                    </div>
                                </div>
                            </div>

                            {(isSelected || indicator.length === 0 || fyear.length === 0 || area.length === 0) ? (
                                <section id='main_dashboard_container' className='flex flex-col'>

                                    <section
                                        id='indicator_compare'
                                        className='flex md:flex-wrap flex-wrap justify-center items-center'>

                                        {(fyear != 'All' && area < 441) ?
                                            (<div className='flex w-full flex-wrap'>
                                                <div className='flex w-full md:w-1/2 justify-center items-center'>
                                                    {(status === "indicator") ? ((found && lineChartData != null && lineChartData != '') ?
                                                        <IndicatorCompareLineChart
                                                            lChartData={lineChartData}
                                                            indicator={indicator}
                                                            fyear={fyear}
                                                            toggleValue={toggleValue}
                                                            indicatorDropdown={indicatorDropdown}
                                                        /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>)
                                                        : null}
                                                </div>
                                                <div className='flex w-full md:w-1/2 justify-center items-center'>
                                                    {(status === "indicator") ? ((found && barChartData != null && barChartData != '') ?
                                                        <IndicatorCompareBarChart
                                                            barChartData={barChartData}
                                                            indicator={indicator}
                                                            toggleValue={toggleValue}
                                                            indicatorDropdown={indicatorDropdown}
                                                        /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>)
                                                        : null}
                                                </div>
                                            </div>
                                            ) :
                                            <div className='flex w-full md:w-9/12 justify-center items-center'>
                                                {(status === "indicator") ? ((found && lineChartData != null && lineChartData != '') ?
                                                    <IndicatorCompareLineChart
                                                        lChartData={lineChartData}
                                                        indicator={indicator}
                                                        fyear={fyear}
                                                        toggleValue={toggleValue}
                                                        indicatorDropdown={indicatorDropdown}
                                                    /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>)
                                                    : null}
                                            </div>
                                        }
                                    </section>

                                    <section id='fy_area_compare'>
                                        <div className='flex w-full md:w-full justify-center items-center' >
                                            {(status === "area") ? ((found && lineChartData != null && lineChartData != '') ?
                                                <AreaCompareLineChart
                                                    lChartData={lineChartData}
                                                    area={area}
                                                    fyear={fyear}
                                                    toggleValue={toggleValue}
                                                /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>) : null}

                                            {(status === "fy") ? ((found && lineChartData != null && lineChartData != '') ?
                                                <FYCompareLineChart
                                                    lChartData={lineChartData}
                                                    fyear={fyear}
                                                    toggleValue={toggleValue}
                                                /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>) : null}
                                        </div>
                                    </section>
                                </section>
                            ) : (
                                SkeletonScreen
                            )}

                        </div>

                        <>
                            <Footer />
                        </>
                    </main>
                </div>
            </div>
        </React.Fragment>
    )

}