import React, { useRef } from 'react'
import './chart.css';
import _ from 'lodash';
import { createQualityData } from '../util';
import DataTable from 'react-data-table-component';
import SideChartNav from './SideChartNav';
import 'bootstrap';
import { CSVLink } from 'react-csv';

export const DataQualityHolder = ({ qualityData, area }) => {
    const componentRef = useRef();

    let mainTitle, csvFileName, table = [];

    if (area === 22) {
        mainTitle = `Data Quality at Block/Taluka Level: ${qualityData.graphTitle.indicator__label}, FY: ${qualityData.graphTitle.financial_year__fy_name}, Month: ${qualityData.graphTitle.month__month_name}`;
        csvFileName = `${qualityData.graphTitle.indicator__label}_${qualityData.graphTitle.financial_year__fy_name}_month_${qualityData.graphTitle.month__month_name}`;
    } else {
        mainTitle = `Data Quality at Block/Taluka Level: ${qualityData.graphTitle.indicator__label}, FY: ${qualityData.graphTitle.financial_year__fy_name}, Month: ${qualityData.graphTitle.month__month_name}`;
        csvFileName = `${qualityData.graphTitle.indicator__label}_${qualityData.graphTitle.financial_year__fy_name}_month_${qualityData.graphTitle.month__month_name}`;
    }

    let filteredData = createQualityData(qualityData);
    let count_values = filteredData[0];
    let zipData = filteredData[1];


    let heading = ["Blocks Reported Zero", "Blocks Reported Null", "Blocks Reported Outlier", "Blocks Reported Duplicate"];

    if (count_values) {
        for (let i = 0; i < count_values.length; i++) {
            table.push({
                ['Reported Zero']: !isNaN(count_values[i].zero_count) ?
                    { type: Number, value: (count_values[i].zero_count) }
                    : { type: String, value: '-' },
                ['Reported Blank']: !isNaN(count_values[i].null_count) ?
                    { type: Number, value: (count_values[i].null_count) }
                    : { type: String, value: '-' },
                ['Reported Outlier']: !isNaN(count_values[i].outlier_count) ?
                    { type: Number, value: (count_values[i].outlier_count) }
                    : { type: String, value: '-' },
                ['Reported Duplicate']: !isNaN(count_values[i].duplicate_count) ?
                    { type: Number, value: (count_values[i].duplicate_count) }
                    : { type: String, value: '-' },
            })
        }
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
                backgroundColor: '#A2D3FA',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
            },
        },
    };


    let columns = [];

    for (const value in table[0]) {
        columns.push({
            name: `${value.replace(/\w+/g, _.toUpper)}`,
            selector: row => row[value],
            sortable: false,
            center: true,
        })
    };

    let tableData = table.map((x) => {
        let data = {};
        Object.keys(x).forEach((a) => {
            data = {
                ...data,
                [`${a}`]: x[`${a}`].value,
            }
        })
        return data;
    })

    return (
        <div className="relative w-full h-full data-quality-table">
            <div className="block absolute w-auto max-h-max right-0" style={{ zIndex: 2 }}>
                <SideChartNav id="dataQuality" screen={null} title={mainTitle} table={table} componentRef={componentRef} />
            </div>
            <div className='dataQualityDiv relative w-full h-full' id="dataQuality" ref={componentRef}>
                <DataTable
                    title={mainTitle}
                    columns={columns}
                    data={tableData}
                    responsive
                    customStyles={customStyles}
                />
            </div>
            <div className="flex justify-center">
                <CSVLink
                    data={zipData}
                    filename={csvFileName}
                    headers={heading}
                    target="_blank"
                >
                    <button className="bg-gray-600 text-white font-bold text-lg py-2 px-4 mt-1 rounded-3xl hover:bg-gray-900" id="btnExport"><i className="bi bi-download"></i> List of Blocks/Talukas</button></CSVLink>
            </div>
        </div>
    )
}

export default DataQualityHolder