import React, { Component, useState, useEffect, useRef, useContext } from 'react'
import { fetchData } from '../util.js'
import useAxios from '../pages/useAxios';
import AuthContext from '../context/AuthContext.js';


export const FilterForm = ({ fyear, district, sub_district, month, area, indicator, areaType, indicatorCategory, districtDropdown, subdtDropdown, fyDropdown, monthDropdown,
    setAreaType, setArea, setMonth, setFyear, setDistrict, setSubDt, setbarChartData, setLineChartData, setTableData, setQualityData, isSelected, setIsSelected,
    setDataDownloadedDate, setBestWorstPerformingBlock }) => {

    const api = useAxios();
    let { authtokens, logoutUser } = useContext(AuthContext)

    const handleFinancialYear = async (e) => {
        setIsSelected(false);
        const fyearSelected = e.target.value;

        setFyear(fyearSelected);
        await fetchData(fyearSelected, month, area, indicator, areaType, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, setDataDownloadedDate, setBestWorstPerformingBlock, api, logoutUser);
        setIsSelected(true);
    };

    const handleMonthChange = async (e) => {
        setIsSelected(false);
        let monthSelected = e.target.value;
        setMonth(monthSelected);

        await fetchData(fyear, monthSelected, area, indicator, areaType, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, setDataDownloadedDate, setBestWorstPerformingBlock, api, logoutUser);
        setIsSelected(true);
    };

    const handleDistrictChange = async (e) => {
        setIsSelected(false);
        const districtName = e.target.value;
        let areaSelected = 22
        let areaTypeSelected = 'District';
        if (districtName == 22) {
            setAreaType('District');
            setArea(22);
            setSubDt(3652);
        }
        else {
            setAreaType('SubDistrict');
            areaSelected = districtName;
            areaTypeSelected = 'SubDistrict';
            setArea(districtName);
        }
        setDistrict(districtName);

        await fetchData(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, setDataDownloadedDate, setBestWorstPerformingBlock, api, logoutUser);
        setIsSelected(true);
    };

    const handleSubDistrictChange = async (e) => {
        setIsSelected(false);
        const subdtName = e.target.value;
        let areaSelected = district;

        let areaTypeSelected = 'SubDistrict';
        setAreaType('SubDistrict');
        setArea(subdtName);

        if (subdtName == 3652) {
            setAreaType('SubDistrict');
            setArea(district);
        }
        else {
            setAreaType('SubDistrict');
            setArea(subdtName);
            areaTypeSelected = 'SubDistrict';
            areaSelected = subdtName;
        }

        setSubDt(subdtName);
        await fetchData(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, setDataDownloadedDate, setBestWorstPerformingBlock, api, logoutUser);
        setIsSelected(true);
    };

    return (
        <React.Fragment>
            <form action="">
                <div className="flex flex-wrap md:flex-row">
                    <div className="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Financial Year</label>
                            <select name="year" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={fyear}
                                onChange={handleFinancialYear}
                                disabled={!isSelected}>

                                {fyDropdown.map((year) => {
                                    return (
                                        <option key={year.fy_id} value={year.fy_id}>{year.fy_name}</option>
                                    )
                                })
                                }

                            </select>
                        </div>
                    </div>
                    <div className="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Month</label>
                            <select name="month" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={month}
                                onChange={handleMonthChange}
                                disabled={!isSelected}>
                                {monthDropdown.map((month) => {
                                    return (
                                        <option key={month.month_id} value={month.month_id}>{month.month_name}</option>
                                    )
                                })
                                }

                            </select>
                        </div>
                    </div>
                    <div className="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select District</label>
                            <select name="district" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={district}
                                onChange={handleDistrictChange}
                                disabled={!isSelected}>
                                {/* <option value={22}>All (MH)</option> */}
                                {districtDropdown.map((data) => {
                                    return (
                                        <option key={data.area_id} value={data.area_id}>{data.area_name}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label htmlFor="" className="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Block/Taluka</label>
                            <select name="subdt" id="" className="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={sub_district}
                                onChange={handleSubDistrictChange}
                                disabled={!isSelected}>
                                <option value={3652}>All</option>
                                {subdtDropdown.filter(sub_district => sub_district.area_parent_id == district).map(filtered_subdt => {
                                    return (

                                        <option key={filtered_subdt.area_id} value={filtered_subdt.area_id}>{filtered_subdt.area_name}</option>
                                    )

                                }
                                )
                                }
                            </select>
                        </div>
                    </div>

                </div>
            </form>
        </React.Fragment>
    )

}

export default FilterForm
