import React, { Component, useRef, useEffect, useContext } from 'react'
import { Line } from 'react-chartjs-2'
import { MDBContainer } from "mdbreact";
import './chart.css'
import { setAreaChartData, decimalPrecision } from '../util.js'
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import fmt from 'indian-number-format';
import Chart from 'chart.js/auto';
import AuthContext from '../context/AuthContext';
import _ from 'lodash';


export const AreaCompareLineChart = ({ lChartData, area, fyear, toggleValue }) => {
    let { user } = useContext(AuthContext);

    const componentRef = useRef();
    let table = [], axisLabel;
    const canvasRef = useRef(null);

    const indicatorName = lChartData[0].indicator.label;

    if (['5', '6'].includes(user.id))
        lChartData = lChartData.filter(l => l.financial_year.fy_id < 5);

    const dataSort = lChartData.sort(function (a, b) {
        return a.financial_year.fy_id - b.financial_year.fy_id || a.month.month_id - b.month.month_id;
    });

    let graphTitle;
    if (fyear === 'All')
        graphTitle = `${indicatorName}, FY:${lChartData[0].financial_year.fy_name} to FY:${lChartData[lChartData.length - 1].financial_year.fy_name}`;
    else
        graphTitle = `${indicatorName}, FY: ${lChartData[0].financial_year.fy_name}`;

    let chartData = setAreaChartData(dataSort, area, toggleValue);

    if (toggleValue === 'count')
        axisLabel = 'Numbers / Count';
    else
        axisLabel = 'Percentage';

    let x = dataSort.filter(f => f.area.area_id == area[0]);

    let tableData = [];

    dataSort.forEach(data => {
        if (fyear === 'All') {
            let fyExists = tableData.find(e => e.FY.value === data.financial_year.fy_name && e.Month.value === data.month.month_name);
            if (toggleValue === 'count') {
                if (fyExists) {
                    tableData[tableData.indexOf(fyExists)] = {
                        ...fyExists,
                        [`${data.area.area_name}`]: { type: Number, value: (data.num_data_value) },
                    }

                } else {
                    tableData.push({
                        FY: { type: String, value: data.financial_year.fy_name },
                        Month: { type: String, value: data.month.month_name },
                        [`${data.area.area_name}`]: { type: Number, value: (data.num_data_value) }
                    })
                }
            } else {
                if (fyExists) {
                    tableData[tableData.indexOf(fyExists)] = {
                        ...fyExists,
                        [`${data.area.area_name}`]: { type: Number, value: decimalPrecision(data.per_data_value) },
                    }
                } else {
                    tableData.push({
                        FY: { type: String, value: data.financial_year.fy_name },
                        Month: { type: String, value: data.month.month_name },
                        [`${data.area.area_name}`]: { type: Number, value: decimalPrecision(data.per_data_value) },
                    })
                }
            }
        }
        else {
            let exists = tableData.find(e => e.Month.value === data.month.month_name);
            if (toggleValue === 'count') {
                if (exists) {
                    tableData[tableData.indexOf(exists)] = {
                        ...exists,
                        [`${data.area.area_name}`]: { type: Number, value: (data.num_data_value) },
                    }

                } else {
                    tableData.push({
                        Month: { type: String, value: data.month.month_name },
                        [`${data.area.area_name}`]: { type: Number, value: (data.num_data_value) }
                    })
                }
            } else {
                if (exists) {
                    tableData[tableData.indexOf(exists)] = {
                        ...exists,
                        [`${data.area.area_name}`]: { type: Number, value: decimalPrecision(data.per_data_value) },
                    }
                } else {
                    tableData.push({
                        Month: { type: String, value: data.month.month_name },
                        [`${data.area.area_name}`]: { type: Number, value: decimalPrecision(data.per_data_value) },
                    })
                }
            }
        }
    });

    var data = {
        labels: x.map(x => x.financial_year.fy_name + ":" + x.month.month_name),
        datasets: chartData,
    };

    var options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                // grid: {
                //     display: false
                // },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    },
                    callback: function (label) {
                        let realLabel = this.getLabelForValue(label)
                        var year = realLabel.split(":")[0];
                        var month = realLabel.split(":")[1];
                        return month;
                    }
                }
            },
            xAxis2: {
                // grid: {
                //     display: false
                // },
                type: "category",
                gridLines: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    },
                    callback: function (label) {
                        let realLabel = this.getLabelForValue(label)
                        var year = realLabel.split(":")[0];
                        var month = realLabel.split(":")[1];
                        if (month === "Oct") {
                            return year;
                        } else {
                            return "";
                        }
                    }
                }
            },
            // x: {
            //     grid: {
            //         display: false
            //     },
            //     ticks: {
            //         color: '#000000'
            //     }
            // },
            y: {
                // grid: {
                //     display: false
                // },
                title: {
                    display: true,
                    text: axisLabel,
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                }
            }
        },
        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    color: '#000000',
                    font: {
                        size: 16,
                    },
                    padding: 20,
                }
            },

            title: {
                display: true,
                color: '#000000',
                font: {
                    size: 18
                },
            },
            tooltip: {
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },
            },
            hover: {
                mode: 'nearest',
                intersect: true
            }

        }
    };

    const screen = useFullScreenHandle();
    let height;

    if (screen.active === true) {
        height = '90vh';

    } else
        height = '60vh';

    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementById('areaLineChart').parentElement.clientWidth > 780) {

                document.getElementById('lineChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                document.getElementById('lineChartDiv').setAttribute('style', 'padding-top: 3.5rem;');
                canvasRef.current.options.scales.x.ticks.font.size = 24;
                canvasRef.current.options.scales.xAxis2.ticks.font.size = 24;
                canvasRef.current.options.scales.y.ticks.font.size = 24;
                options.plugins.tooltip.bodyFont.size = 24;
                options.plugins.tooltip.titleFont.size = 24;
                options.plugins.legend.labels.font.size = 24;
                canvasRef.current.height = window.screen.height;
                canvasRef.current.options.scales.y.title.font.size = 24;
                document.getElementById('areaLineChart').setAttribute('style', 'padding:20px')
            }
        }
        else {
            document.getElementsByClassName("graph-title")[0].setAttribute('style', 'font-size: .875rem');
            document.getElementById('lineChartDiv').setAttribute('style', 'padding-top:0;');
            canvasRef.current.options.scales.x.ticks.font.size = 12;
            canvasRef.current.options.scales.xAxis2.ticks.font.size = 12;
            canvasRef.current.options.scales.y.ticks.font.size = 12;
            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            options.plugins.legend.labels.font.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            document.getElementById('areaLineChart').setAttribute('style', 'padding:00px')
        }
    };

    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>
                <div className="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max left-15 right-5" style={{ zIndex: 2 }}>
                        <SideChartNav id="areaLineChart" screen={screen} title={graphTitle} table={tableData} componentRef={componentRef} />
                    </div>
                    <div className='relative w-full' id="areaLineChart" ref={componentRef}>
                        <div className="absolute right-5 left-5 mx-10 w-auto">
                            <div className="text-center font-bold graph-title" id='lineChartTitle'>{`${graphTitle}`}</div>
                        </div>
                        <div className='relative w-full px-24' id='lineChartDiv'>
                            <Line data={data} options={options} style={{ position: 'relative', height: height, width: '80vw' }} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default AreaCompareLineChart