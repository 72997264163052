import React, { useRef, useEffect, useState } from 'react'
import './chart.css';
import BootstrapTable from 'react-bootstrap-table-next';
import _, { sortBy } from 'lodash';
import { scaleSequential, interpolateRdYlGn, min, max } from 'd3';
import { createTableData, createPercentArray } from '../util';
import SideChartNav from './SideChartNav';
import useAxios from '../pages/useAxios';


export const TableHolder = ({ tableData, indicatorCategory }) => {
    const api = useAxios();

    const [categoryName, setCategoryName] = useState([]);

    useEffect(() => {
        const getCategories = async () => {
            const result = await api.get('/categories');

            let filterCategory = result.data.filter(c => c.category_id === indicatorCategory).map(c => c.category_name);
            setCategoryName(filterCategory)
        }
        getCategories();
    }, []);

    const componentRef = useRef();
    let table = [];
    let mainTitle = `Category: ${categoryName}, FY: ${tableData[0].financial_year.fy_name}, Month: ${tableData[0].month.month_name}`;
    let area_all = createTableData(tableData);
    area_all = sortBy(area_all, 'area');
    let percentMaxMinArray = createPercentArray(tableData);

    let headerStyle = { width: '100%', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#A2D3FA', fontSize: '18px' }
    let columns = [{
        dataField: 'area',
        text: 'Area Name',
        sort: true,
        align: () => {
            return 'center';
        },
        style: { fontWeight: 'bold' },
        headerStyle,
    }];


    const getColumns = async () => {
        for (let i = 0; i < area_all[0].indDetails.length; i++) {
            let colorScale;
            if (area_all[0].indDetails[i].indicator.variant != 'Numeric') {
                let arrayMaxMin = percentMaxMinArray.filter(f => f.indicatorId == area_all[0].indDetails[i].indicator.indicator_id)
                if (area_all[0].indDetails[i].indicator.type === 'positive')
                    colorScale = scaleSequential().domain([arrayMaxMin[0].min, arrayMaxMin[0].max]).interpolator(interpolateRdYlGn);
                else
                    colorScale = scaleSequential().domain([arrayMaxMin[0].max, arrayMaxMin[0].min]).interpolator(interpolateRdYlGn);
            }
            if (area_all[0].indDetails[i].indicator.variant === 'Numeric') {
                columns.push({
                    dataField: `indDetails[${i}].numeric_value`,
                    text: `No. of ${area_all[0].indDetails[i].indicator.label}`,
                    // headerFormatter: priceFormatter,
                    sort: true,
                    align: () => {
                        return 'center';
                    },
                    style: { fontWeight: 'bold' },
                    headerStyle,
                });
            } else if (area_all[0].indDetails[i].indicator.variant === 'Percent') {
                if (area_all[0].indDetails[i].indicator.type === 'positive') {
                    columns.push({
                        dataField: `indDetails[${i}].percent_value`,
                        text: `${area_all[0].indDetails[i].indicator.label} (%)`,
                        // headerFormatter: priceFormatter,
                        sort: true,
                        align: () => {
                            return 'center';
                        },
                        style: (cell) => {
                            if (cell >= 100)
                                return { backgroundColor: '#006837', fontWeight: 'bold' };
                            else
                                return { backgroundColor: colorScale(cell), fontWeight: 'bold' };
                        },
                        headerStyle,
                    });
                } else {
                    columns.push({
                        dataField: `indDetails[${i}].percent_value`,
                        text: `${area_all[0].indDetails[i].indicator.label} (%)`,
                        // headerFormatter: priceFormatter,
                        sort: true,
                        align: () => {
                            return 'center';
                        },
                        style: (cell) => {
                            return { backgroundColor: colorScale(cell), fontWeight: 'bold' };
                        },
                        headerStyle,
                    });
                }
            } else if (area_all[0].indDetails[i].indicator.variant === 'Both') {
                columns.push({
                    dataField: `indDetails[${i}].numeric_value`,
                    text: `No. of ${area_all[0].indDetails[i].indicator.label}`,
                    // headerFormatter: priceFormatter,
                    sort: true,
                    align: () => {
                        return 'center';
                    },
                    style: { fontWeight: 'bold' },
                    headerStyle,
                });
                if (area_all[0].indDetails[i].indicator.type === 'positive') {
                    columns.push({
                        dataField: `indDetails[${i}].percent_value`,
                        text: `${area_all[0].indDetails[i].indicator.label} (%)`,
                        // headerFormatter: priceFormatter,
                        sort: true,
                        align: () => {
                            return 'center';
                        },
                        style: (cell) => {
                            if (cell >= 100)
                                return { backgroundColor: '#006837', fontWeight: 'bold' };
                            else
                                return { backgroundColor: colorScale(cell), fontWeight: 'bold' };
                        },
                        headerStyle,
                    });
                } else {
                    columns.push({
                        dataField: `indDetails[${i}].percent_value`,
                        text: `${area_all[0].indDetails[i].indicator.label} (%)`,
                        // headerFormatter: priceFormatter,
                        sort: true,
                        align: () => {
                            return 'center';
                        },
                        style: (cell) => {
                            return { backgroundColor: colorScale(cell), fontWeight: 'bold' };
                        },
                        headerStyle,
                    });
                }
            }
        }
    }


    getColumns();

    area_all.forEach((x) => {
        let row = {
            Area: { type: String, value: _.startCase(x.area) },
        };

        for (let i = 0; i < x.indDetails.length; i++) {

            if (area_all[0].indDetails[i].indicator.variant === 'Numeric') {
                row = {
                    ...row,
                    [`${x.indDetails[i].indicator.label}`]: !isNaN(x.indDetails[i].numeric_value) ?
                        { type: Number, value: (x.indDetails[i].numeric_value) }
                        : { type: String, value: '-' }
                }
            } else if (area_all[0].indDetails[i].indicator.variant === 'Percent') {
                row = {
                    ...row,
                    [`${x.indDetails[i].indicator.label}`]: !isNaN(x.indDetails[i].percent_value) ?
                        { type: Number, value: (x.indDetails[i].percent_value) }
                        : { type: String, value: '-' }
                }
            } else if (area_all[0].indDetails[i].indicator.variant === 'Both') {
                row = {
                    ...row,
                    [`No. of ${x.indDetails[i].indicator.label}`]: !isNaN(x.indDetails[i].numeric_value) ?
                        { type: Number, value: (x.indDetails[i].numeric_value) }
                        : { type: String, value: '-' },
                    [`${x.indDetails[i].indicator.label} %`]: !isNaN(x.indDetails[i].percent_value) ?
                        { type: Number, value: (x.indDetails[i].percent_value) }
                        : { type: String, value: '-' }
                }
            }
        }

        table.push(row);
    })

    return (
        <>
            <div className="block absolute w-auto max-h-max right-0" style={{ zIndex: 2 }}>
                <SideChartNav id="color-coded-table" screen={null} title={mainTitle} table={table} componentRef={componentRef} />
            </div>
            <div className="relative w-full h-full table-container" id="color-coded-table">
                <div className="absolute right-2 left-2 md:right-5 md:left-5 mx-10 lg:mx-8 w-auto">
                    <div className="text-center font-bold md:font-semibold lg:font-bold text-sm md:text-base lg:text-base pb-8">{`${mainTitle}`}</div>
                </div>
                <br />
                <BootstrapTable
                    data={area_all} columns={columns} keyField='id' wrapperClasses="table-responsive" />
            </div>
        </>
    )
}

export default TableHolder;