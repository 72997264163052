import React from 'react'

export default function Footer() {
    return (
        <React.Fragment>
            <footer className="generic-footer px-5">
                <div className="row section-row">
                    <div className="col-md-12">
                        <div className="d-flex flex-row-reverse">
                            <div className="p-2"><img src="/assets/img/ctara.svg" alt="CTARA"></img></div>
                            {/* <div className="p-2"><img src="assets/img/unicef.svg" alt="UNICEF"></img></div> */}
                            <div className="p-2"><img src="/assets/img/iitb.svg" alt="IIT Bombay"></img></div>
                            <div className="p-2"><img src="/assets/img/nhm_mh_logo.svg" alt="National Health Mission Maharashtra"></img></div>
                            <div className="p-2"><p className="credit">Developed in <br />Collaboration with</p></div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
