import React, { Component, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './chart.css';
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { axisTitle, decimalPrecision } from '../util.js';
import fmt from 'indian-number-format';


export const LineChartHolder = ({ lineChartData, toggleValue, indicatorCategory, indicatorVariant }) => {
    const componentRef = useRef();
    let table = [];

    const dataSort = lineChartData.sort(function (a, b) {
        return a.month.month_id - b.month.month_id;
    });

    const indicatorName = lineChartData[0].indicator.label;
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
    }, []);

    let dataValue,
        graphTitle = `${indicatorName}, ${lineChartData[0].area.area_name}, FY: ${lineChartData[0].financial_year.fy_name}`,
        indicatorId = dataSort[0].indicator.indicator_id;

    // If only numeric values present then show numeric line chart
    if (toggleValue === 'count') {
        dataValue = dataSort.map(x => x.num_data_value);

        if (lineChartData) {
            for (var i = 0; i < lineChartData.length; i++) {
                table.push({
                    Month: { type: String, value: lineChartData[i].month.month_name },
                    [`${lineChartData[0].indicator.label}`]: !isNaN(lineChartData[i].num_data_value) ?
                        { type: Number, value: (lineChartData[i].num_data_value) }
                        : { type: String, value: '-' }

                })
            }
        };
    }
    else {
        dataValue = dataSort.map(x => x.per_data_value);

        if (lineChartData) {
            for (var i = 0; i < lineChartData.length; i++) {
                table.push({
                    Month: { type: String, value: lineChartData[i].month.month_name },
                    [`${lineChartData[0].indicator.label}`]: !isNaN(lineChartData[i].per_data_value) ?
                        { type: Number, value: decimalPrecision(lineChartData[i].per_data_value) }
                        : { type: String, value: '-' }
                })

            }
        }
    }

    let paddingValue;
    var data = {
        labels: dataSort.map(x => x.month.month_name),
        datasets: [{
            data: dataValue,
            borderWidth: 1,
            borderColor: "#00439D",
            backgroundColor: "#00439D",
        }]
    };

    var options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
            },
            y: {
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: axisTitle(indicatorCategory, toggleValue, indicatorId, indicatorVariant),
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                }
            }
        },
        layout: {
            padding: {
                top: 30,
                right: 25,
            }
        },
        plugins: {
            datalabels: {
                display: true,
                anchor: "end",
                align: "end",
                // rotation: rotateLabel ? -90 : 0,
                formatter: function (value) {
                    return (toggleValue === "count") ? fmt.format(value) : decimalPrecision(value);
                },
                labels: {
                    value: {
                        color: "black",
                        font: {
                            size: 12
                        }
                    }
                }
            },
            legend: {
                display: false,

            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let val = context.raw;
                        return (toggleValue === "count") ? fmt.format(val) : decimalPrecision(val);
                    }
                },
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },

            },

        }
    };

    const screen = useFullScreenHandle();


    if (screen.active === true)
        paddingValue = '2rem';

    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementById('lineChart').parentElement.clientWidth > 780) {

                document.getElementById('lineChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                canvasRef.current.options.scales.x.ticks.font.size = 24;
                canvasRef.current.options.scales.y.ticks.font.size = 24;
                canvasRef.current.options.scales.y.title.font.size = 24;
                options.plugins.tooltip.bodyFont.size = 24;
                options.plugins.tooltip.titleFont.size = 24;
                canvasRef.current.options.scales.y.title.font.size = 24;
                document.getElementById('lineChart').setAttribute('style', 'padding-top:20px');
                options.plugins.datalabels.labels.value.font.size = 24;
            }
        }
        else {
            document.getElementById('lineChartTitle').setAttribute('style', 'font-size: 1rem');
            canvasRef.current.options.scales.x.ticks.font.size = 12;
            canvasRef.current.options.scales.y.ticks.font.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            document.getElementById('lineChart').setAttribute('style', 'padding-top:0px');
            options.plugins.datalabels.labels.value.font.size = 12;
        }
    };

    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>
                <div className="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max top-0 p-0 m-0 right-0" style={{ zIndex: 2 }}>
                        <SideChartNav id="lineChart" className="top-0 p-0 m-0 right-0" screen={screen} title={graphTitle} table={table} componentRef={componentRef} />
                    </div>
                    <div className='relative w-full' id="lineChart" ref={componentRef}>
                        <div className="absolute right-4 left-3 md:right-5 md:left-5  mx-4 w-auto">
                            <div id="lineChartTitle" className="graph-title text-center font-bold md:font-semibold lg:font-bold pb-8 text-sm">{`${graphTitle}`}</div>
                        </div>
                        <div className='relative w-full pt-14'>
                            <Line data={data} style={{ position: 'relative', height: '40vh', width: '80vw', padding: paddingValue }} options={options} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default LineChartHolder