import React, { Component, useRef, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import './chart.css';
import { interpolateColors } from '../util.js';
import * as d3 from 'd3';
import './chart.css';
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import fmt from 'indian-number-format';


export const PieChartHolder = ({ barChartData, indicatorType }) => {
    const componentRef = useRef();
    let windowWidth = window.screen.width;
    const canvasRef = useRef(null);

    const dataSort = barChartData.sort(function (a, b) {
        return b.pie_data_value - a.pie_data_value;
    });
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
    }, []);

    const indicatorName = barChartData[0].indicator.label;
    let graphTitle = `${indicatorName}, FY: ${barChartData[0].financial_year.fy_name}, Month: ${barChartData[0].month.month_name}`;;

    const colorScale = d3.interpolateReds;

    const colorRangeInfo = {
        colorStart: 1,
        colorEnd: 0,
        useEndAsStart: false,
    };

    var colors = interpolateColors(dataSort.length, colorScale, colorRangeInfo);

    var data = {
        labels: dataSort.map(x => x.area.area_name),

        datasets: [{
            data: dataSort.map(x => x.pie_data_value),
            backgroundColor: colors,
        }]
    };


    let height, paddingValue;
    const screen = useFullScreenHandle();

    let table = [];
    if (barChartData) {
        for (var i = 0; i < barChartData.length; i++) {
            table.push({
                area: { type: String, value: barChartData[i].area.area_name },
                [`${barChartData[0].indicator.label}`]: !isNaN(barChartData[i].pie_data_value) ?
                    { type: Number, value: (barChartData[i].pie_data_value) }
                    : { type: String, value: '-' }
            })
        }
    };

    if (screen.active === true) {
        height = '80vh';
        paddingValue = '2rem';
    } else
        height = '40vh';


    var options = {
        responsive: true, maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false,
                anchor: "end",
                align: "end",
                // rotation: rotateLabel ? -90 : 0,
                formatter: (val) => (val),
                labels: {
                    value: {
                        color: "black",
                        font: {
                            size: 12
                        }
                    },
                }
            },
            legend: {
                display: true,
                position: "right",
                labels: {
                    color: '#000000',
                    boxWidth: 40,
                    font: {
                        size: 12
                    },
                    usePointStyle: true,
                    filter: (legendItem) => {
                        return legendItem.index < 10;
                    },
                }
            },
            tooltip: {
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },

            },
        }
    };



    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementById('pieChart').parentElement.clientWidth > 780) {
                document.getElementById('pieChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                canvasRef.current.options.plugins.legend.labels.font.size = 24;
                options.plugins.tooltip.bodyFont.size = 24;
                options.plugins.tooltip.titleFont.size = 24;
                document.getElementById('pieChart').setAttribute('style', 'padding-top:20px')


            }
        }
        else {
            document.getElementById('pieChartTitle').setAttribute('style', 'font-size: 1rem');
            canvasRef.current.options.plugins.legend.labels.font.size = 12;
            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            document.getElementById('pieChart').setAttribute('style', 'padding-top:0px')
        }
    };
    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>
                <div className="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max top-0 right-0" style={{ zIndex: 2 }}>
                        <SideChartNav id="pieChart" screen={screen} title={graphTitle} table={table} componentRef={componentRef} />
                    </div>
                    <div className='relative w-full' id="pieChart" ref={componentRef}>
                        <div className="absolute right-5 left-5 mx-4 w-auto">
                            <div id='pieChartTitle' className="text-center font-bold md:font-semibold lg:font-bold text-sm md:text-base lg:text-base pb-8">{`${graphTitle}`}</div>
                        </div>
                        <div className='relative w-full pt-14 pie-chart-div'>
                            <Pie id='pie' style={{ position: 'relative', height: height, width: '80vw', padding: paddingValue }} data={data} options={options} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default PieChartHolder