export const jsonPath = [
    {
        type: 'District',
        path: require('../contents/json/mh_dt_with_bmumbai25052022.json')
    },
    {
        type: 'DtwithMS',
        path: require('../contents/json/mh_dtlevel25052022.json')
    },
    {
        type: 'SubDistrict',
        path: require('../contents/json/mh_sub_dt_equi2hmis071221.json')
    }


]